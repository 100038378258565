import React, { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import weekdayPlugin from "dayjs/plugin/weekday";
import { Icon } from "@iconify/react";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";

interface SingleCalendarComponentProps {
  isFirstCalendar: boolean;
  isLastCalendar: boolean;
  dateFormat: string;
  currentMonth: Dayjs;
  nextMonth: () => void;
  prevMonth: () => void;
  onChange: (date: Date) => void;
  handleDayClick: (date: Dayjs) => void;
  start: Dayjs;
  end: Dayjs;
  selectsEnd: boolean;
  selectsStart?: boolean;
  range: { start: Dayjs; end: Dayjs };
  isFullScreen: boolean;
  isRange: boolean;
  endDate: string;
  hideCalendar?: any;
  selectEnd: boolean;
  hoveredDate: any;
  sethoveredDate: any;
  setshowOpacityOnHover: any;
  name: string;
  category?: any;
}
let allDates: {
  dates: {
    day: number;
    month: number;
    year: number;
    isCurrentMonth: boolean;
    isCurrentDay: boolean;
    isFuture: boolean;
    hideCalendar: any;
    category?: any;
  }[];
}[] = [];

export const SingleRangeCalendarComponent = (
  props: SingleCalendarComponentProps
) => {
  const {
    currentMonth,
    nextMonth,
    prevMonth,
    isLastCalendar,
    isFirstCalendar,
    handleDayClick,

    selectsStart,
    selectsEnd,
    isRange,
    endDate,
    hoveredDate,
    sethoveredDate,
    setshowOpacityOnHover,
    category,
    name,
  } = props;

  const { start, end } = props.range;
  var now = dayjs();

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isBetween);

  const [arrayOfDays, setArrayOfDays] = useState(allDates);
  // const [hoveredDate, sethoveredDate] = useState(end);
  const formateDateObject = (date: Dayjs) => {
    const clonedObject = { ...date.toObject() };
    const formatedObject = {
      day: clonedObject.date,
      month: clonedObject.months,
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentMonth.month(),
      isCurrentDay: date.isToday(),
      isFuture: date.isAfter(now) || date.isToday(),
    };

    return formatedObject;
  };
  const getAllDays = () => {
    let currentDate = currentMonth.startOf("month").weekday(0);
    const nextMonth = currentMonth.add(1, "month").month();
    let allDates: any = [];
    let weekDates = [];
    let weekCounter = 1;
    /* Creating an array of dates for the current month. */
    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);
      weekDates.push(formated);
      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }
      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }
    setArrayOfDays(allDates);
  };

  useEffect(() => {
    getAllDays();
  }, [currentMonth]);

  const renderHeader = () => {
    const dateFormat = "MMM YYYY";
    return (
      <div className="flex justify-center pt-5 lg:pt-0 ">
        <div
          className={`absolute  hidden md:block left-2 cursor-pointer invisible top-[22 px] lg:top-[16px] ${
            isFirstCalendar ? "!visible" : ""
          }`}
          onClick={() => prevMonth()}
        >
          <Icon
            icon={"material-symbols:chevron-left"}
            className="text-gray-500 dark:text-dark-text-primary w-6 h-6 rounded-full   border-[1px] hover:text-newText-100 hover:border-newText-100"
          />
        </div>
        <strong className="dark:text-dark-text-primary">
          {currentMonth.format(dateFormat)}
        </strong>

        <div
          className={` absolute hidden md:block  right-2 cursor-pointer invisible top-[22px] lg:top-[16px] ${
            isLastCalendar ? "!visible" : ""
          }`}
          onClick={() => nextMonth()}
        >
          <Icon
            icon={"material-symbols:chevron-right"}
            className="text-gray-500 dark:text-dark-text-primary w-6 h-6 rounded-full   border-[1px] hover:text-newText-100 hover:border-newText-100"
          />
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "dd";
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          className="text-center w-[40px] h-[40px] flex items-center justify-center flex-1 dark:text-dark-text-primary"
          key={i}
        >
          {now.weekday(i).format(dateFormat)}
        </div>
      );
    }
    return <div className="flex my-2 text-sm">{days}</div>;
  };
  const renderCells = () => {
    const rows: JSX.Element[] = [];
    let days: JSX.Element[] = [];
    arrayOfDays?.forEach((week, index) => {
      week?.dates?.forEach((d, i) => {
        const theday = dayjs(`${d.year}-${d.month + 1}-${d.day}`);
        days.push(
          <div
            className={` text-center cursor-pointer text-sm
                rounded-full w-[32px] h-[32px] flex items-center justify-center flex-1 mx-auto
            ${
              // basic layout colors
              !d.isCurrentMonth
                ? " !bg-transparent  hover:bg-transparent text-transparent "
                : d.isCurrentDay
                ? "text-newText-100 border-[1px] border-newText-100  "
                : !d.isFuture ||
                  (endDate && selectsEnd && theday.isBefore(start))
                ? "text-gray-400 "
                : "dark:text-dark-text-primary"
            } 
          
            ${
              // selected dates color
              (theday.isSame(start, "day") || theday.isSame(end, "day")) &&
              d.isCurrentMonth
                ? "!bg-gradient-to-tr  from-newSecondary-100 to-newPrimary-100 !text-white  "
                : " "
            }
            
            
            ${
              // end date hover color
              selectsEnd && theday.isBefore(start) ? "hover:bg-transparent" : ""
            }
            ${
              // range color
              isRange && theday.isBetween(start, end, "day")
                ? "bg-gray-200  dark:bg-dark-bg-secondary dark:text-dark-text-primary  "
                : ""
            }
            ${
              // end date hover color
              !d.isFuture
                ? "hover:bg-transparent"
                : "hover:bg-gradient-to-tr  from-newSecondary-100 to-newPrimary-100 hover:text-white"
            }
            ${
              props.selectsEnd &&
              isRange &&
              theday.isBetween(start, hoveredDate, "day") &&
              theday.isAfter(start)
                ? "bg-gray-200 dark:bg-dark-bg-secondary   "
                : ""
            } `}
            onClick={() => {
              if (d.isCurrentMonth && d.isFuture) {
                if (name !== "return-date" && theday.isSame(start, "day"))
                  return;
                if (!selectsStart && props.selectsEnd && !props.isFullScreen) {
                  props.hideCalendar();
                }
                if (selectsEnd && theday.isBefore(start)) return;
                props.onChange(new Date(d.year, d.month, d.day));
                handleDayClick(theday);
                (!props.isFullScreen &&
                  props.selectEnd &&
                  props.hideCalendar()) ||
                  false;
              }
            }}
            onMouseEnter={() => {
              /*  !props.selectEnd &&  */ !props.isFullScreen &&
                setshowOpacityOnHover(true);
              sethoveredDate(theday);
            }}
            onMouseLeave={() => {
              /*  !props.selectEnd &&  */ !props.isFullScreen &&
                setshowOpacityOnHover(false);
              // sethoveredDate(now);
              sethoveredDate(end);
            }}
            key={i}
          >
            <span>{d.day}</span>
          </div>
        );
      });
      rows.push(
        <div
          className={` 
         grid  gap-2 grid-cols-7 mb-2 `}
          key={index}
        >
          {days}
        </div>
      );
      days = [];
    });
    return <div className="">{rows}</div>;
  };
  /* SHOWING FOR THE MOBILE CASES */
  const diff = Math.ceil(
    dayjs(props.range.end).diff(dayjs(props.range.start), "day", true)
  );
  const noOfDays: any = diff + ` night${(diff > 1 && "s") || ""}`;
  return (
    <>
      <div className="calendar-space ">
        <div
          className={`${
            props.isFullScreen ? "w-screen px-6" : "w-72"
          } text-black `}
        >
          {renderHeader()}
          {renderDays()}
          {renderCells()}
        </div>
      </div>
      {/* <button className="absolute px-6 py-2 text-center text-white rounded visibile lg:invisible calendar-space-btn bg-newText-100">
        Done
      </button> */}

      {/* <div
        className="lg:hidden 
        fixed bottom-0 right-0 left-0 bg-white p-4 w-full drop-shadow-[0px_0px_8px_rgba(0,0,0,0.02)]"
      >
        <button
          onClick={() => props.hideCalendar()}
          className="text-center w-full py-2 px-6 text-white font-semibold bg-gradient-to-tr  from-newSecondary-100 to-newPrimary-100 !rounded-lg"
        >
          <span className="">Done</span>
          {category !== "Flight" && <span>{` (${noOfDays})`}</span>}
        </button>
      </div> */}
    </>
  );
};
