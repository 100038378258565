import React, { useEffect, useState } from "react";
import { FullscreenOnMobile } from "comp/FullscreenOnMobile";
import { XceltripCalendar } from "../XceltripCalendar";
import { Icon } from "@iconify/react";
import { useRouter } from "next/router";
import useIsSmallDevice from "src/customHooks/useIsSmallDevice";

interface Props {
  label: string;
  data: any;
  name: any;
  alterOtherDate?: any;
  selectStart?: any;
  selectEnd?: any;
  endDate?: any;
  startDate?: any;
  errors?: any;
  minDate?: any;
  maxYear?: number;
  placeholder: string;
  handleDateChange: any;
  className?: string;
  setisStart?: any;
  isStart?: boolean;
  selectsOne?: boolean;
  isRange: boolean;
  category?: string;
  alterCalendar?: any;
  setAlterCalendar?: any;
  positionRight?: any;
  daterightMobile?: any;
}

const DateSelection = (props: Props) => {
  // let isMobileDevice = true
  const router = useRouter();
  const {
    label = "",
    alterOtherDate = "",
    selectStart = false,
    selectEnd = false,
    endDate,
    minDate,
    startDate,
    placeholder = "",
    name,
    data,
    handleDateChange,
    errors,
    maxYear = 1,
    selectsOne,
    isRange,
    category,
    alterCalendar,
    setAlterCalendar,
    positionRight,
    daterightMobile,
  } = props;

  const isSmallDevice = useIsSmallDevice();

  const [isMobileCheckinCalendarShown, setisMobileCheckinCalendarShown] =
    React.useState<any>(false);
  const [prevSelectedData, setPrevSelectedData] = useState(data);
  useEffect(() => {
    if (data && isMobileCheckinCalendarShown) {
      setPrevSelectedData(data);
    }
  }, [isMobileCheckinCalendarShown]);
  const defaultArray = ["checkin", "checkout"];
  const handlePrevState = () => {
    defaultArray.map((x) => handleDateChange(x, prevSelectedData[x], false));
  };
  // console.log(prevDate.checkin,"defaultArray", prevDate.checkout);
  const calendarComponent = (directlyShowCalendar: boolean) => {
    return (
      <div>
        <XceltripCalendar
          monthsToShow={directlyShowCalendar ? 12 : 2}
          positionRight={
            positionRight && router.pathname === "/"
              ? " text-right lg:border-l "
              : " "
          }
          daterightMobile={daterightMobile}
          dateFormat="MMM, DD"
          onChange={(date) =>
            handleDateChange(
              selectsOne ? name : props.isStart ? name : alterOtherDate,
              date,
              alterOtherDate
            )
          }
          selected={data[name]}
          alterCalendar={alterCalendar}
          setAlterCalendar={setAlterCalendar}
          selectsStart={!isRange ? true : props.isStart}
          selectsEnd={!isRange ? false : !props.isStart}
          startDate={data[startDate] || data[name]}
          endDate={data[endDate] || data[name]}
          className={props.className + " inline-block w-full min-w-fit "}
          directlyShowCalendar={directlyShowCalendar}
          setisStart={props.setisStart}
          selectsOne={props.selectsOne}
          isRange={isRange}
          selectEnd={selectEnd}
          hideCalendar={() => setisMobileCheckinCalendarShown(false)}
          category={category}
          name={name}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      <FullscreenOnMobile
        header={
          <>
            <div className="flex">
              <strong className="m-auto">{props.label}</strong>
              <button
                aria-label="Close Calender"
                onClick={() => {
                  setisMobileCheckinCalendarShown(false);
                  handlePrevState();
                }}
              >
                <Icon icon="akar-icons:cross" />
              </button>
            </div>
          </>
        }
        content={calendarComponent(true)}
        isShown={isSmallDevice && isMobileCheckinCalendarShown}
        setisShown={setisMobileCheckinCalendarShown}
      />
      <div
        onClick={(e) => {
          if (isSmallDevice) {
            setisMobileCheckinCalendarShown(true);
            return;
          }

          // console.log(datepickerRef.current)
          // datepickerRef.current.setOpen(true)
          // datepickerRef.current.setFocus(true)
          // datepickerRef.current.handleBlur = () => console.log("blurred")
        }}
      >
        <label
          className={`!text-sm pb-1.5 flex  dark:text-dark-text-primary ${positionRight}`}
        >
          {label}
        </label>
        {/* {calendarComponent(false)} */}
        <XceltripCalendar
          monthsToShow={2}
          positionRight={
            positionRight && router.pathname === "/"
              ? " text-right lg:border-l"
              : " "
          }
          daterightMobile={daterightMobile}
          dateFormat="MMM, DD"
          onChange={(date) =>
            handleDateChange(
              selectsOne ? name : props.isStart ? name : alterOtherDate,
              date,
              alterOtherDate
            )
          }
          selected={data[name]}
          alterCalendar={alterCalendar}
          setAlterCalendar={setAlterCalendar}
          selectsStart={!isRange ? true : props.isStart}
          selectsEnd={!isRange ? false : !props.isStart}
          startDate={data[startDate] || data[name]}
          endDate={data[endDate] || data[name]}
          className={props.className + " inline-block w-full min-w-fit "}
          directlyShowCalendar={false}
          setisStart={props.setisStart}
          selectsOne={props.selectsOne}
          isRange={isRange}
          selectEnd={selectEnd}
          hideCalendar={() => setisMobileCheckinCalendarShown(false)}
          category={category}
          name={name}
        />
        {/* <DatePicker
          monthsShown={2}
          dateFormat="MMM, d"
          selected={data[name]}
          selectsEnd={selectEnd}
          selectsStart={selectStart}
          // withPortal={isMobileDevice()}
          excludeDates={[data.checkin]}
          placeholderText={placeholder}
          onFocus={(e) => e.target.blur()}
          minDate={minDate || data[name]}
          maxDate={addyearInDate(maxYear)}
          onChangeRaw={handleDateChangeRaw}
          endDate={data[endDate] || data[name]}
          className="p-0 font-semibold border-0 cursor-pointer date_picker custom_calendar"
          startDate={data[startDate] || data[name]}
          onChange={(date) => handleDateChange(name, date, alterOtherDate)}
          shouldCloseOnSelect={false}
        /> */}
      </div>

      {errors && errors.startDate && (
        <span data-tooltip={errors.startDate}>
          <i className="icon-exclamation-triangle red" />
        </span>
      )}
    </React.Fragment>
  );
};

export default DateSelection;
