import React from "react";
import { RangeCalendar } from "./RangeCalendar";

interface XceltripCalendarProps {
  monthsToShow: number;
  dateFormat: string;
  onChange: (date: Date) => void;
  selected: string;
  selectsEnd: boolean;
  selectsStart?: boolean;
  startDate: string;
  endDate: string;
  className: string;
  directlyShowCalendar?: boolean;
  setisStart: any;
  selectsOne?: boolean;
  isRange: boolean;
  hideCalendar: any;
  selectEnd: boolean;
  category?: string;
  name?: string;
  alterCalendar?: boolean;
  setAlterCalendar?: any;
  positionRight?: any;
  daterightMobile?: any;
}

export const XceltripCalendar = (props: XceltripCalendarProps) => {
  return <RangeCalendar {...props} />;
};
