import dayjs, { Dayjs } from "dayjs";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import useComponentVisible from "src/customHooks/useComponentVisible";
import { SingleRangeCalendarComponent } from "./SingleRangeCalendarComponent";

import { Icon } from "@iconify/react";
import useIsSmallDevice from "src/customHooks/useIsSmallDevice";

interface RangeCalendarProps {
  monthsToShow: number;
  dateFormat: string;
  onChange: (date: Date) => void;
  selected: string;
  selectsEnd: boolean;
  selectsStart?: boolean;
  startDate: string;
  endDate: string;
  className: string;
  directlyShowCalendar?: boolean;
  setisStart: any;
  selectsOne?: boolean;
  isRange: boolean;
  hideCalendar: any;
  selectEnd: boolean;
  range?: any;
  category?: string;
  name?: any;
  setAlterCalendar?: any;
  alterCalendar?: any;
  positionRight?: any;
  daterightMobile?: any;
}

export const RangeCalendar = (props: RangeCalendarProps) => {
  var now = dayjs();
  const router = useRouter();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const isSmallDevice = useIsSmallDevice(() => setIsComponentVisible(false));

  const {
    directlyShowCalendar = false,
    name,
    selectsStart,
    alterCalendar,
    setAlterCalendar,
    positionRight,
    daterightMobile,
  } = props;
  const [showOpacityOnHover, setshowOpacityOnHover] = useState(false);
  const [range, setRange] = useState({
    start: dayjs(props.startDate) || now,
    end: dayjs(props.selected) || now.add(1, "day"),
  });
  const [hoveredDate, sethoveredDate] = useState(range?.end);

  useEffect(() => {
    if ((name === "checkout" || name === "return-date") && alterCalendar) {
      setIsComponentVisible(true);
    }
  }, [alterCalendar]);

  const handleDayClick = (day: Dayjs) => {
    /* props.setisStart &&
      props.setisStart((prev: any) => {
        return !prev;
      }); */
    if (setAlterCalendar) {
      if ((name === "checkin" || name === "onward") && selectsStart) {
        setIsComponentVisible(false);
        setAlterCalendar(true);
      } else {
        setAlterCalendar(false);
      }
    }
  };
  const [currentMonth, setCurrentMonth] = useState(now);
  useEffect(() => {
    setRange({
      start: dayjs(props.startDate) || now,
      end: dayjs(props.endDate) || now.add(1, "day"),
    });
  }, [props.selected, props.startDate, props.endDate]);

  const nextMonth = () => {
    const plus = currentMonth.add(1, "month");
    setCurrentMonth(plus);
  };
  const prevMonth = () => {
    const minus = currentMonth.subtract(1, "month");
    setCurrentMonth(minus);
  };

  // useEffect(() => {
  //   console.log(props.selectsOne)
  //   if (props.selectsOne && props.isRange) props.setisStart(false)
  // }, [])

  const renderDescription = () => {
    const start = dayjs(props.startDate);
    const end = dayjs(props.endDate);
    const diff = end.diff(start, "day");
    const diffCheck = Math.ceil(
      dayjs(hoveredDate).diff(dayjs(props.startDate), "day", true)
    );

    return (
      <>
        {showOpacityOnHover && diffCheck > 0 ? (
          <React.Fragment>
            <p className="mb-0 pt-2  text-center border-t-[1px] opacity-25 dark:text-dark-text-primary dark:opacity-100">
              <span className="font-medium">
                {diffCheck} night{diffCheck > 1 && "s"}
              </span>
              : {start.format("MMM DD")} to {hoveredDate.format("MMM DD")}
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="mb-0 pt-2  text-center border-t-[1px] dark:text-dark-text-primary">
              <span className="font-medium">
                {diff} night{diff > 1 ? "s" : ""}
              </span>
              : {start.format("MMM DD")} to {end.format("MMM DD")}
            </p>
          </React.Fragment>
        )}
      </>
    );
  };

  const renderCalendars = () => {
    return (
      <>
        {[...Array(props.monthsToShow)].map((item, index) => {
          return (
            <SingleRangeCalendarComponent
              dateFormat={props.dateFormat}
              currentMonth={currentMonth.add(index, "month")}
              nextMonth={nextMonth}
              prevMonth={prevMonth}
              isFirstCalendar={index == 0}
              isLastCalendar={props.monthsToShow == index + 1}
              onChange={props.onChange}
              handleDayClick={handleDayClick}
              range={range}
              start={range.start}
              end={range.end}
              selectsEnd={props.selectsEnd}
              selectsStart={props.selectsStart}
              isFullScreen={directlyShowCalendar}
              key={"singlecal" + index}
              isRange={props.isRange}
              hoveredDate={hoveredDate}
              sethoveredDate={sethoveredDate}
              endDate={props.endDate}
              selectEnd={props.selectEnd}
              setshowOpacityOnHover={setshowOpacityOnHover}
              hideCalendar={() =>
                directlyShowCalendar
                  ? props.hideCalendar()
                  : setIsComponentVisible(!isComponentVisible)
              }
              category={props.category}
              name={name}
            />
          );
        })}
      </>
    );
  };

  if (directlyShowCalendar) {
    const diff = Math.ceil(
      dayjs(range.end).diff(dayjs(range.start), "day", true)
    );
    const noOfDays: any = diff + ` night${(diff > 1 && "s") || ""}`;
    return (
      <div className="absolute-123 overflow-x-hidden overflow-y-auto h-[400px]">
        {renderCalendars()}
        <div className="fixed bottom-0 w-full py-3 font-semibold text-center text-white capitalize rounded-md bg-gradient-to-r from-newPrimary-100 to-newSecondary-100">
          <button
            onClick={() => props.hideCalendar()}
            className="text-center w-full py-2 px-6 text-white font-semibold bg-gradient-to-tr  from-newSecondary-100 to-newPrimary-100 !rounded-lg"
          >
            <span className="">Done</span>
            {props.category !== "Flight" && <span>{` (${noOfDays})`}</span>}
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`${props.className} ${positionRight}`}>
        <div
          className="flex items-center gap-2 cursor-pointer lg:block "
          onClick={() => {
            setIsComponentVisible(true);
          }}
        >
          {/* {console.log(
            dayjs(props.selected).format(props.dateFormat)?.split(",")
          )} */}
          <div
            className={`flex  flex-1 gap-1  items-center  lg:inline-block  ${daterightMobile}`}
          >
            <span className="inline-block">
              <Icon
                icon={`solar:calendar-outline`}
                className={`${
                  router.pathname === "/"
                    ? isComponentVisible
                      ? "lg:text-newPrimary-100"
                      : "text-gray-500"
                    : "text-white"
                } mr-2 lg:mb-[3px]`}
              />
            </span>

            <span
              className={`date  !font-semibold  text-xs lg:mt-[-6px] text-center order-2  lg:capitalize lg:font-normal lg:text-base ${
                isComponentVisible ? "lg:text-newPrimary-100" : ""
              }`}
            >
              {dayjs(props.selected).format(props.dateFormat)?.split(",")[0]}
            </span>

            <span
              className={`text-3xl font-semibold text-center date lg:text-base  ${
                isComponentVisible ? "lg:text-newPrimary-100" : ""
              } `}
            >
              {dayjs(props.selected).format(props.dateFormat)?.split(",")[1]}
            </span>
          </div>

          {/* {dayjs(props.selected).format(props.dateFormat)} */}
        </div>
        {isComponentVisible && !isSmallDevice && (
          <div
            className={`absolute animate_modal  lg:mt-10  bg-white p-4 transition-all   rounded-xl  shadow-m1
            before:absolute before:left-[20px] before:border-b-[8px]
            before:border-l-[8px] before:border-white before:dark:border-b-dark-bg-tertiary before:top-[-8px]
            before:border-r-[8px] before:border-r-transparent
            before:border-l-transparent z-[1] dark:bg-dark-bg-tertiary ${
              name === "checkout" ? "before:left-[300px] -left-[177px]" : ""
            }`}
            ref={ref}
          >
            <div className={`${directlyShowCalendar ? "" : "lg:flex gap-8"}`}>
              {renderCalendars()}
            </div>
            {props.isRange && renderDescription()}
          </div>
        )}
      </div>
    );
  }
};
